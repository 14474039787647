/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { string, bool, object, elementType } from 'prop-types';
import get from 'lodash.get';
import Label from './Label';
import {
  InputErrorDiv,
  TextAreaInput,
  TextInputDiv,
  SecondLabelDiv,
} from './styledComponents';

const MultiLineInput = ({
  name,
  label,
  secondLabel,
  disabled,
  optional,
  defaultValue,
  validationRules,
  autoComplete,
  isPrivate,
  fieldHelp,
  hidden,
  trimPasted,
}) => {
  const {
    register: formRegister,
    formState: { errors: formErrors },
    getFieldId,
    setValue,
    formState,
  } = useFormContext();

  const isFormSubmitted = formState.isSubmitted;
  const inputId = getFieldId(name);

  const error = get(formErrors, name);

  const className = useMemo(() => {
    if (!error && !secondLabel) {
      return undefined;
    }

    let c = '';
    if (error) {
      c = `${c} error`;
    }
    if (secondLabel) {
      c = `${c} secondLabel`;
    }
    return c;
  }, [error, secondLabel]);

  const wrapperClassName = useMemo(() => {
    if (!secondLabel && !hidden) {
      return undefined;
    }

    let c = '';
    if (secondLabel) {
      c = `${c} secondLabel`;
    }
    if (hidden) {
      c = `${c} hidden`;
    }
    return c;
  }, [secondLabel, hidden]);

  const handlePaste = useCallback(
    (event) => {
      if (!event.target.disabled) {
        event.preventDefault(); // Prevent the default paste behavior

        // eslint-disable-next-line no-nested-ternary
        const pastedInput = event.clipboardData
          ? event.clipboardData.getData('Text')
          : window.clipboardData
            ? window.clipboardData.getData('Text')
            : '';

        if (pastedInput && typeof pastedInput === 'string') {
          // Split the pasted input into lines
          const lines = pastedInput.split(/\r?\n/);

          // Find the number of leading whitespaces in the first line
          const firstLineIndentation = lines[0].match(/^\s*/)
            ? lines[0].match(/^\s*/)[0].length
            : 0;

          // Adjust indentation based on the first line for all lines
          const adjustedLines = lines.map((line) => {
            // Remove the first line's indentation from each line
            const newLine = line.startsWith(' ')
              ? line.substring(firstLineIndentation)
              : line;
            return newLine;
          });

          // Rejoin the lines into a single string with line breaks
          const textUpdated = adjustedLines.join('\n');

          // Set the updated text
          setValue(name, textUpdated, {
            shouldDirty: true,
            shouldValidate: isFormSubmitted,
          });

          // Optional: Move the cursor to the end of the text area
          const input = event.target;
          input.focus();
          const { length } = input.value;
          input.setSelectionRange(length, length);
        }
      }
    },
    [isFormSubmitted, name, setValue],
  );

  return (
    <>
      {!hidden && label && (
        <Label
          inputId={inputId}
          label={label}
          optional={optional}
          fieldHelp={fieldHelp}
        />
      )}
      <TextInputDiv className={wrapperClassName}>
        <TextAreaInput
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          id={inputId}
          className={className}
          data-private={isPrivate ? true : undefined}
          aria-invalid={error ? 'true' : 'false'}
          {...formRegister(name, validationRules)}
          onPaste={!disabled && trimPasted ? handlePaste : undefined}
        />
        {secondLabel && <SecondLabelDiv>{secondLabel}</SecondLabelDiv>}
      </TextInputDiv>
      {error && !hidden && (
        <InputErrorDiv role='alert'>{error.message}</InputErrorDiv>
      )}
    </>
  );
};

MultiLineInput.defaultProps = {
  label: undefined,
  secondLabel: undefined,
  disabled: false,
  optional: false,
  defaultValue: undefined,
  validationRules: undefined,
  autoComplete: 'off',
  isPrivate: false,
  fieldHelp: undefined,
  hidden: false,
  trimPasted: false,
};

MultiLineInput.propTypes = {
  name: string.isRequired,
  label: string,
  secondLabel: string,
  disabled: bool,
  optional: bool,
  defaultValue: string,
  // eslint-disable-next-line react/forbid-prop-types
  validationRules: object,
  autoComplete: string,
  isPrivate: bool,
  fieldHelp: elementType,
  hidden: bool,
  trimPasted: bool,
};

export default MultiLineInput;
