/* eslint-disable react/jsx-props-no-spreading */
import { string, number, bool, shape } from 'prop-types';
import PrestoClusters from '../../PrestoClusters';
import Page from '../../Page';
import { useEnableFeature } from '../../utils/featureHooks';
import { InformationGridDiv } from '../../Page/styledComponents';
import { SuccessBadge, WarningBadge } from '../../Badge';

const display = {
  title: 'Spot Settings',
  enableSpot: 'Enable Spot',
  enableSpotSense: 'Enable Spot Sense',
  prestoWorkerSpotPercentage: 'Presto Worker Spot Percentage (%)',
  ondemandBaseCapacity: 'On Demand Base Capacity',
  enableASGCapacityRebalance: 'Enable ASG Capacity Rebalance',
  changeAutoScalingPolicyButton: 'Change Spot Policy',
  spotWorkerNodes: 'Spot Worker Nodes',
  enabled: 'Enabled',
  disabled: 'Disabled',
};

const SpotSettings = ({
  navTarget,
  prestoCluster,
  enableEnableSpot,
  spotSenseEnabled,
  spotPercentage,
  baseCapacityOndemand,
  enableASGCapacityRebalance,
  status,
  workflowInProgress,
}) => {
  const enableEditLink = useEnableFeature();

  const canChangeSpot =
    (status === PrestoClusters.constants.status.active ||
      status === PrestoClusters.constants.status.inactive) &&
    !workflowInProgress;

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
        <div className='buttons'>
          <Page.PrimaryLink
            to={PrestoClusters.routes.getPrestoClusterEnableSpotRoute(
              prestoCluster.prestoClusterId,
            )}
            disabled={!canChangeSpot || !enableEditLink}
          >
            {display.changeAutoScalingPolicyButton}
          </Page.PrimaryLink>
        </div>
      </Page.BoxHeader>
      <InformationGridDiv style={{ '--grid-columns': 2 }}>
        <div className='cell'>
          <div className='title'>{display.enableSpot}</div>
          <div className='data'>
            {enableEnableSpot ? (
              <SuccessBadge>{display.enabled}</SuccessBadge>
            ) : (
              <WarningBadge>{display.disabled}</WarningBadge>
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.spotWorkerNodes}</div>
          <div className='data'>
            {prestoCluster.clusterConfiguration.enableSpot ? (
              prestoCluster.clusterConfiguration.spotWorkerNodes
            ) : (
              <WarningBadge>{display.disabled}</WarningBadge>
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.enableSpotSense}</div>
          <div className='data'>
            {spotSenseEnabled ? (
              <SuccessBadge>{display.enabled}</SuccessBadge>
            ) : (
              <WarningBadge>{display.disabled}</WarningBadge>
            )}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.prestoWorkerSpotPercentage}</div>
          <div className='data'>{enableEnableSpot ? spotPercentage : '-'}</div>
        </div>
        <div className='cell'>
          <div className='title'>{display.ondemandBaseCapacity}</div>
          <div className='data'>
            {enableEnableSpot ? baseCapacityOndemand : '-'}
          </div>
        </div>
        <div className='cell'>
          <div className='title'>{display.enableASGCapacityRebalance}</div>
          <div className='data'>
            {enableASGCapacityRebalance ? (
              <SuccessBadge>{display.enabled}</SuccessBadge>
            ) : (
              <WarningBadge>{display.disabled}</WarningBadge>
            )}
          </div>
        </div>
      </InformationGridDiv>
    </Page.BoxNavTarget>
  );
};

SpotSettings.propTypes = {
  navTarget: string.isRequired,
  prestoCluster: shape({
    prestoClusterId: string.isRequired,
  }).isRequired,
  status: string.isRequired,
  enableEnableSpot: bool.isRequired,
  spotSenseEnabled: bool.isRequired,
  spotPercentage: number.isRequired,
  baseCapacityOndemand: number.isRequired,
  enableASGCapacityRebalance: bool.isRequired,
  workflowInProgress: bool.isRequired,
};

export default SpotSettings;
