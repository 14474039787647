import { apiVerbs, createApiActionType } from '../../rayApi/actionTypes';

const componentName = 'COMPUTE_PLANE';
export const getAll = createApiActionType(apiVerbs.GET, `${componentName}_ALL`);
export const get = createApiActionType(apiVerbs.GET, componentName);
// export const post = createApiActionType(apiVerbs.POST, componentName);
export const patch = createApiActionType(apiVerbs.PATCH, componentName);
export const putDestroy = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_DESTROY`,
);

export const putUpgrade = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_UPGRADE`,
);

export const putCheckProvisioningPolicies = createApiActionType(
  apiVerbs.PUT,
  `${componentName}_CHECK_PROVISIONING_POLICIES`,
);

export const postDeploy = createApiActionType(
  apiVerbs.POST,
  `${componentName}_DEPLOY`,
);
// The new getConfiguration Endpoint
export const getConfiguration = createApiActionType(
  apiVerbs.GET,
  `${componentName}_CONFIGURATION`,
);

export const getRegionInfo = createApiActionType(
  apiVerbs.GET,
  `${componentName}_REGION_INFO`,
);

export const getVPCSubnets = createApiActionType(
  apiVerbs.GET,
  `${componentName}_SUBNETS`,
);

export const verifyProvisioningRole = createApiActionType(
  apiVerbs.PUT,
  `${componentName}/VERIFY_PROVISIONING_ROLE`,
);

export const getComputePlaneLatestVersion = createApiActionType(
  apiVerbs.GET,
  `${componentName}_LASTEST_VERSION`,
);
