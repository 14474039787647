import { createApiAction } from '../../rayApi/actions';
import * as actionTypes from './actionTypes';
import * as api from './api';

export const getAll = () => {
  return createApiAction(actionTypes.getAll, api.getAll, true);
};

export const get = (computePlaneId) => {
  return createApiAction(actionTypes.get, api.get, true, { computePlaneId });
};

// While we only support one compute plane, we do not need this
// b/c the compute plane is created at first log in
// export const create = ({
//   awsRegion,
//   awsAvailabilityZones,
//   awsRoleARN,
//   subdomainName,
//   createSandbox,
//   vpcCIDR,
//   vpcId,
//   privateSubnets,
//   publicSubnets,
//   isBYOVPC,
// }) => {
//   return createApiAction(actionTypes.post, api.post, true, null, {
//     awsRegion,
//     awsAvailabilityZones,
//     awsRoleARN,
//     subdomainName,
//     createSandbox,
//     vpcCIDR,
//     vpcId,
//     privateSubnets,
//     publicSubnets,
//     isBYOVPC,
//   });
// };

export const patch = (
  computePlaneId,
  {
    awsRegion,
    awsAvailabilityZones,
    awsRoleARN,
    createSandbox,
    vpcCIDR,
    vpcId,
    privateSubnets,
    publicSubnets,
    isBYOVPC,
    awsAccountId,
    roleCreationMethod,
    customTags,
  },
) => {
  return createApiAction(
    actionTypes.patch,
    api.patch,
    true,
    { computePlaneId },
    {
      awsRegion,
      awsAvailabilityZones,
      awsRoleARN,
      createSandbox,
      vpcCIDR,
      vpcId,
      privateSubnets,
      publicSubnets,
      isBYOVPC,
      awsAccountId,
      roleCreationMethod,
      customTags,
    },
  );
};

export const destroy = (computePlaneId) => {
  return createApiAction(actionTypes.putDestroy, api.putDestroy, true, {
    computePlaneId,
  });
};

export const upgrade = (computePlaneId) => {
  return createApiAction(actionTypes.putUpgrade, api.putUpgrade, true, {
    computePlaneId,
  });
};

export const putCheckProvisioningPolicies = (computePlaneId) => {
  return createApiAction(
    actionTypes.putCheckProvisioningPolicies,
    api.putCheckProvisioningPolicies,
    true,
    { computePlaneId },
  );
};

export const deploy = (computePlaneId) => {
  return createApiAction(actionTypes.postDeploy, api.deploy, true, {
    computePlaneId,
  });
};

export const getConfiguration = (computePlaneId) => {
  return createApiAction(
    actionTypes.getConfiguration,
    api.getConfiguration,
    true,
    { computePlaneId },
  );
};

export const getRegionInfo = (computePlaneId, region) => {
  return createApiAction(actionTypes.getRegionInfo, api.getRegionInfo, true, {
    computePlaneId,
    region,
  });
};

export const getVPCSubnets = (computePlaneId) => {
  return createApiAction(actionTypes.getVPCSubnets, api.getVPCSubnets, true, {
    computePlaneId,
  });
};

export const verifyProvisioningRole = ({
  computePlaneId,
  verifyProvisioningRoleData,
}) => {
  return createApiAction(
    actionTypes.verifyProvisioningRole,
    api.verifyProvisioningRole,
    true,
    { computePlaneId },
    verifyProvisioningRoleData,
  );
};

export const getComputePlaneLatestVersion = (computePlaneId) => {
  return createApiAction(
    actionTypes.getComputePlaneLatestVersion,
    api.getComputePlaneLatestVersion,
    true,
    { computePlaneId },
  );
};
