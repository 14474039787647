import {
  call,
  put,
  takeEvery,
  select,
  take,
  race,
  all,
} from 'redux-saga/effects';
import auth from '../../auth';
import ComputePlane from '../ComputePlane';
import logger from '../../utils/logger';
import * as actions from './actions';
import * as selectors from './selectors';

function* loadAccount() {
  const tenantId = yield select(selectors.getTenantId);
  yield put(actions.get(tenantId));
  yield put(ComputePlane.actions.getAll());

  const result = yield race({
    success: take(ComputePlane.actionTypes.getAll.success),
    failure: take(ComputePlane.actionTypes.getAll.failure),
  });

  if (result.failure) {
    logger.error('Error loading compute plane. Cannot continue.');
    return;
  }

  const computePlane = yield select(ComputePlane.selectors.getComputePlane);
  if (!computePlane) {
    logger.error('No compute plane loaded. Cannot continue.');
    return;
  }

  const { computePlaneId } = computePlane;
  yield put(ComputePlane.actions.getComputePlaneLatestVersion(computePlaneId));
  yield put(ComputePlane.actions.getConfiguration(computePlaneId));
}

function* cancelableLoadAccount() {
  yield race({
    logOut: take(auth.actionTypes.signOut),
    loadAccount: call(loadAccount),
  });
}

export default function* accountSetupRootSaga() {
  yield all([takeEvery(auth.actionTypes.signIn, cancelableLoadAccount)]);
}
