import Help from '../../Help';

const EnableSpot = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable Spot</Help.HelpHeader>
        <Help.P>
          If enabled, the Presto worker nodes will be provisioned with on-demand
          instances and spot instances.
        </Help.P>
        <Help.P>
          This will decrease compute cost but might increase the risk of query
          failure.
        </Help.P>
        <Help.P>
          If spot instances are being reclaimed by AWS, the coordinator will
          remove the Spot worker node and retry provisioning new instances.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const EnableSpotSense = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable Spot Sense</Help.HelpHeader>
        <Help.P>
          Turns on or off spot sense for spot instances for the autoscaling
          group.
        </Help.P>
        <Help.P>
          When enabled, the Auto Scaling Group is monitored, and instances will
          fall back to On-Demand if necessary.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const SpotPercentage = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Spot Percentage</Help.HelpHeader>
        <Help.P>
          Presto worker nodegroup spot instance percentage value is between 0 to
          100.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const OnDemandBaseCapacity = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Ondemand Base Capacity</Help.HelpHeader>
        <Help.P>
          Specify how much OnDemand capacity the EKS worker nodegroup ASG should
          have for its base portion before scaling by percentages.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const EnableASGCapacityRebalance = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable ASG Capacity Rebalance</Help.HelpHeader>
        <Help.P>
          Turns on/off capacity rebalance for spot instances for the autoscaling
          group.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const module = {
  EnableSpot,
  EnableSpotSense,
  SpotPercentage,
  OnDemandBaseCapacity,
  EnableASGCapacityRebalance,
};

export default module;
