import { connect } from '../../utils/redux';
import PrestoClusters from '../PrestoClusters';
import { ChangeEnableSpot } from './components';

const mapStateToProps = (state, props) => {
  return {
    prestoClusterId: PrestoClusters.selectors.getPrestoClusterId(state, props),
    prestoCluster: PrestoClusters.selectors.getPrestoCluster(state, props),
    spotEnabled: PrestoClusters.selectors.getPrestoClusterSpotEnabled(
      state,
      props,
    ),
    enableASGCapacityRebalance:
      PrestoClusters.selectors.getEnableASGCapacityRebalance(state, props),
    spotSenseEnabled: PrestoClusters.selectors.getPrestoClusterSpotSenseEnabled(
      state,
      props,
    ),
    spotPercentage: PrestoClusters.selectors.getPrestoClusterSpotPercentage(
      state,
      props,
    ),
    baseCapacityOndemand:
      PrestoClusters.selectors.getPrestoClusterBaseCapcityOndemand(
        state,
        props,
      ),
    autoScalingType: PrestoClusters.selectors.getAutoScalingType(state, props),
    staticWorkerNodes: PrestoClusters.selectors.getStaticWorkerNodes(
      state,
      props,
    ),
    dynamicWorkerNodes: PrestoClusters.selectors.getDynamicWorkerNodes(
      state,
      props,
    ),
    status: PrestoClusters.selectors.getPrestoClusterStatus(state, props),
    name: PrestoClusters.selectors.getPrestoClusterName(state, props),
    notFound: PrestoClusters.selectors.getIsNotFound(state, props),
    actionStatus: PrestoClusters.selectors.getActionStatus(state, props),
    usePop: PrestoClusters.selectors.getUsePop(state),
    workflowInProgress:
      PrestoClusters.selectors.getPrestoClusterWorkflowInProgress(state, props),
  };
};

export default connect(mapStateToProps, {
  loadPrestoCluster: PrestoClusters.actions.get,
  saveEnableSpot: PrestoClusters.actions.putEnableSpot,
})(ChangeEnableSpot);
