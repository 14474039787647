import { useState, useCallback, useContext, useEffect } from 'react';
import { string, func, number, shape, bool } from 'prop-types';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import { useEnableFeature } from '../../utils/featureHooks';
import { SupportDiv } from './styledComponents';

const display = {
  title: 'Support',
  sendLogsToSupport: 'Send Logs to Support',
  sendLogsButton: 'Send Logs',
  sendLogsDescription:
    'This will copy the system logs to a private IBM watsonx.data AWS S3 bucket to provide our support team key debugging information for troubleshooting. This operation requires the cluster to be running.',
  sendLogsSuccessTitle: 'Success',
  sendLogsSuccess: 'Your logs are being transferred to our support team.',
  sendDebuggingToSupport: 'Send Debugging Info to Support',
  sendDebuggingButton: 'Send Debug Info',
  sendDebuggingDescription:
    'This will collect JStack and JFR debugging information from each node in the Presto cluster. It will then copy the information to a private IBM watsonx.data AWS S3 bucket to provide our support team key debugging information for troubleshooting. This operation requires the cluster to be running.',
  sendDebuggingSuccessTitle: 'Success',
  sendDebuggingSuccess:
    'The debugging info is being transferred to our support team.',
  sendTriggerOnDemandPrestoDebuggingToSupport: 'Send JVM Heap Dumps to Support',
  sendTriggerOnDemandPrestoDebuggingButton: 'Send Heap Dumps',
  sendTriggerOnDemandPrestoDebuggingDescription:
    'This will trigger heap dump generation and collection on desired Kubernetes nodes for this nodegroup. It will then copy the information to a private IBM watsonx.data AWS S3 bucket to provide our support team key debugging information for troubleshooting. This operation requires the cluster to be running.',
  cancelButton: 'Cancel',
  communityEdition: 'This feature is not available to community edition.',
  requiresClusterRecreation:
    'This feature only available for newer Presto cluster versions.',
};

const Support = ({
  navTarget,
  prestoClusterId,
  prestoCluster,
  actionStatus,
  exportLogs,
  exportDebuggingInfo,
  showSendDebugging,
}) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();
  const actionInProgress = useContext(ActionInProgressStateContext);

  const enableManageLink = useEnableFeature({
    requireActiveComputePlane: true,
    requireValidSubscription: false,
  });

  const [confirmSendLogs, setConfirmSendLogs] = useState(false);
  const [confirmSendDebugging, setConfirmSendDebugging] = useState(false);

  const onSendLogs = useCallback(() => {
    const actionId = Date.now();
    setConfirmSendLogs(actionId);
    exportLogs(prestoClusterId, actionId);
  }, [prestoClusterId, exportLogs]);

  const onSendDebugging = useCallback(() => {
    const actionId = Date.now();
    setConfirmSendDebugging(actionId);
    exportDebuggingInfo(prestoClusterId, actionId);
  }, [prestoClusterId, exportDebuggingInfo]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmSendLogs
    ) {
      setConfirmSendLogs(!!actionStatus.success);
    }
  }, [actionStatus, confirmSendLogs]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === confirmSendDebugging
    ) {
      setConfirmSendDebugging(!!actionStatus.success);
    }
  }, [actionStatus, confirmSendDebugging]);

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    (actionStatus.name === 'exportLogs' ||
      actionStatus.name === 'exportDebuggingInfo' ||
      actionStatus.name === 'triggerOnDemandPrestoDebugging');

  const showSendLogs =
    prestoCluster.status === PrestoClusters.constants.status.active &&
    !prestoCluster.workflowInProgress;

  return (
    <Page.BoxNavTarget navTarget={navTarget}>
      <Page.BoxHeader>
        <h2>{display.title}</h2>
      </Page.BoxHeader>
      <SupportDiv>
        {showActionStatusError && (
          <div style={{ gridColumn: '1 / 3' }}>
            <Banner scrollIntoView title={actionStatus.error} />
          </div>
        )}
        {confirmSendLogs !== true && (
          <>
            <div>
              <h3>{display.sendLogsToSupport}</h3>
              <div>{display.sendLogsDescription}</div>
            </div>
            <div>
              <Form.TertiaryButton
                label={display.sendLogsButton}
                onClick={onSendLogs}
                disabled={
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !showSendLogs ||
                  !enableManageLink ||
                  isCommunityEditionTenant
                }
                showLoading={
                  actionStatus &&
                  actionStatus.inProgress &&
                  actionStatus.actionId === confirmSendLogs
                }
                tooltip={
                  isCommunityEditionTenant
                    ? display.communityEdition
                    : undefined
                }
              />
            </div>
          </>
        )}
        {confirmSendLogs === true && (
          <>
            <div>
              <h3>{display.sendLogsSuccessTitle}</h3>
              <div>{display.sendLogsSuccess}</div>
            </div>
            <div />
          </>
        )}
        {prestoCluster.clusterConfiguration.workerContainerType !==
          'prestoCPP' &&
          confirmSendDebugging !== true && (
            <>
              <div>
                <h3>{display.sendDebuggingToSupport}</h3>
                <div>{display.sendDebuggingDescription}</div>
              </div>
              <div>
                <Form.TertiaryButton
                  label={display.sendDebuggingButton}
                  onClick={onSendDebugging}
                  disabled={
                    actionInProgress ||
                    (actionStatus && actionStatus.inProgress) ||
                    !showSendLogs ||
                    !showSendDebugging ||
                    !enableManageLink ||
                    isCommunityEditionTenant
                  }
                  showLoading={
                    actionStatus &&
                    actionStatus.inProgress &&
                    actionStatus.actionId === confirmSendDebugging
                  }
                  tooltip={
                    // eslint-disable-next-line no-nested-ternary
                    isCommunityEditionTenant
                      ? display.communityEdition
                      : !showSendDebugging
                        ? display.requiresClusterRecreation
                        : null
                  }
                />
              </div>
            </>
          )}
        {prestoCluster.clusterConfiguration.workerContainerType !==
          'prestoCPP' &&
          confirmSendDebugging === true && (
            <>
              <div>
                <h3>{display.sendDebuggingSuccessTitle}</h3>
                <div>{display.sendDebuggingSuccess}</div>
              </div>
              <div />
            </>
          )}
        {prestoCluster.clusterConfiguration.workerContainerType !==
          'prestoCPP' && (
          <>
            <div>
              <h3>{display.sendTriggerOnDemandPrestoDebuggingToSupport}</h3>
              <div>{display.sendTriggerOnDemandPrestoDebuggingDescription}</div>
            </div>
            <div>
              <Page.TertiaryLink
                to={PrestoClusters.routes.getPrestoDebuggingRoute(
                  prestoCluster.prestoClusterId,
                )}
                disabled={
                  actionInProgress ||
                  (actionStatus && actionStatus.inProgress) ||
                  !showSendLogs ||
                  !enableManageLink ||
                  isCommunityEditionTenant
                }
              >
                {/* this is not centralized */}
                {display.sendTriggerOnDemandPrestoDebuggingButton}
              </Page.TertiaryLink>
            </div>
          </>
        )}
      </SupportDiv>
    </Page.BoxNavTarget>
  );
};

Support.defaultProps = {
  actionStatus: undefined,
};

Support.propTypes = {
  navTarget: string.isRequired,
  prestoClusterId: string.isRequired,
  prestoCluster: shape({
    status: string.isRequired,
  }).isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    inProgress: bool.isRequired,
    success: bool,
  }),
  exportLogs: func.isRequired,
  exportDebuggingInfo: func.isRequired,
  showSendDebugging: bool.isRequired,
};

export default Support;
