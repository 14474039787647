import { useCallback, useContext, useMemo } from 'react';
import { bool, string, shape, number } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import { useEnableFeature } from '../../utils/featureHooks';
import PrestoClusterInfo from './PrestoClusterInfo';
import PendingStatus from './PendingStatus';
import PageHelp from './PageHelp';
import * as constants from '../../PrestoClusterAdd/constants';
import ChangeEnableSpotHelp from './ChangeEnableSpotHelp';
import * as utils from '../../PrestoClusterAdd/utils';
import Data from '../../Data';

const display = {
  header: 'Spot Settings',
  cancelButton: 'Cancel',
  updateButton: 'Update',
  savingButton: 'Updating...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  enableSpotLabel: 'Enable Spot Instances',
  enableASGCapacityRebalance: 'Enable Capacity Rebalance',
  formContentHeader: 'Spot Settings',
  enableSpotSenseLabel: 'Spot Sense',
  spotpercentageLabel: 'Spot Percentage (%)',
  ondemandbasecapacityLabel: 'Ondemand Base Capacity',
  ondemandbasecapacityError:
    'Ondemand Base Capcity must be b/w 0 to maximum number of worker nodes',
  spotpercentagError: 'Enter Valid value from 0 to 100',
};

const ChangeEnableSpotForm = ({
  prestoCluster,
  spotEnabled,
  spotSenseEnabled,
  spotPercentage,
  baseCapacityOndemand,
  enableASGCapacityRebalance,
  autoScalingType,
  staticWorkerNodes,
  dynamicWorkerNodes,
  name,
  status,
  actionStatus,
  prestoClusterId,
  submitSuccess,
  usePop,
  workflowInProgress,
}) => {
  const { watch, getValues } = useFormContext();
  const enableSpotValue = watch('enableSpot');

  const enableFeature = useEnableFeature();
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const canChangeEnableSpot =
    enableFeature &&
    (status === PrestoClusters.constants.status.active ||
      status === PrestoClusters.constants.status.inactive) &&
    !workflowInProgress;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editEnableSpot';

  const spotPercentageValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBePercentageInRange(
        0,
        100,
        display.spotpercentagError,
      )(values.prestoWorkerSpotPercentage);
    };
  }, [getValues]);

  const ondemandBaseCapacityValidation = useMemo(() => {
    let maxOndemandBaseCapcity;
    if (autoScalingType === null) {
      maxOndemandBaseCapcity = prestoCluster.clusterConfiguration.workerNodes;
    }
    if (
      autoScalingType === constants.autoScaling.cpu ||
      autoScalingType === constants.autoScaling.queuedQueries
    ) {
      maxOndemandBaseCapcity = dynamicWorkerNodes;
    }
    if (autoScalingType === constants.autoScaling.idleCostSavings) {
      maxOndemandBaseCapcity = staticWorkerNodes;
    }
    return () => {
      const values = getValues();
      return utils.mustBePercentageInRange(
        0,
        maxOndemandBaseCapcity,
        display.ondemandbasecapacityError,
      )(values.ondemandBaseCapacity);
    };
  }, [
    getValues,
    dynamicWorkerNodes,
    staticWorkerNodes,
    autoScalingType,
    prestoCluster,
  ]);

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.TertiaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {!canChangeEnableSpot && (
            <Banner scrollIntoView>
              <PendingStatus name={name} status={status} />
            </Banner>
          )}
          {showActionStatusError && (
            <Banner scrollIntoView title={actionStatus.error} />
          )}
          {!enableFeature && (
            <Banner scrollIntoView title={display.featureUnavailable} />
          )}
          <PrestoClusterInfo name={name} />
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.header}</h2>
            </Page.BoxHeader>
            <Page.FormContent>
              <div>
                <h3>{display.formContentHeader}</h3>
                {/* <div>{display.formExplaination}</div> */}
              </div>
              <div>
                <Form.CheckboxInput
                  name='enableSpot'
                  defaultChecked={spotEnabled}
                  label={display.enableSpotLabel}
                  disabled={
                    submitInProgress || !canChangeEnableSpot || !enableFeature
                  }
                  fieldHelp={ChangeEnableSpotHelp.EnableSpot}
                />
                {enableSpotValue && (
                  <>
                    <Form.CheckboxInput
                      name='enableSpotSense'
                      label={display.enableSpotSenseLabel}
                      defaultChecked={spotSenseEnabled}
                      fieldHelp={ChangeEnableSpotHelp.EnableSpotSense}
                      disabled={
                        submitInProgress ||
                        !canChangeEnableSpot ||
                        !enableFeature
                      }
                    />
                    <Form.TextInput
                      name='prestoWorkerSpotPercentage'
                      type='integer'
                      label={display.spotpercentageLabel}
                      defaultValue={spotPercentage}
                      disabled={
                        submitInProgress ||
                        !canChangeEnableSpot ||
                        !enableFeature
                      }
                      validationRules={{
                        required: display.spotpercentagError,
                        validate: spotPercentageValidation,
                        valueAsNumber: true,
                      }}
                      fieldHelp={ChangeEnableSpotHelp.SpotPercentageWorker}
                    />
                    <Form.TextInput
                      name='ondemandBaseCapacity'
                      type='integer'
                      label={display.ondemandbasecapacityLabel}
                      defaultValue={baseCapacityOndemand}
                      disabled={
                        submitInProgress ||
                        !canChangeEnableSpot ||
                        !enableFeature
                      }
                      validationRules={{
                        required: display.ondemandbasecapacityError,
                        validate: ondemandBaseCapacityValidation,
                        valueAsNumber: true,
                      }}
                      fieldHelp={ChangeEnableSpotHelp.OndemandBaseCapacity}
                    />
                    <Form.CheckboxInput
                      name='enableASGCapacityRebalance'
                      defaultChecked={enableASGCapacityRebalance}
                      label={display.enableASGCapacityRebalance}
                      disabled={
                        submitInProgress ||
                        !canChangeEnableSpot ||
                        !enableFeature
                      }
                      fieldHelp={ChangeEnableSpotHelp.EnableCapacityRebalance}
                    />
                  </>
                )}
                {!enableSpotValue && (
                  <>
                    <Form.CheckboxInput
                      name='enableSpotSensePlaceholder'
                      label={display.enableSpotSenseLabel}
                      disabled
                      fieldHelp={ChangeEnableSpotHelp.EnableSpotSense}
                    />
                    <Data.DataField
                      label={display.spotpercentageLabel}
                      disabled
                      fieldHelp={ChangeEnableSpotHelp.SpotPercentageWorker}
                    />
                    <Data.DataField
                      label={display.ondemandbasecapacityLabel}
                      disabled
                      fieldHelp={ChangeEnableSpotHelp.OndemandBaseCapacity}
                    />
                    <Form.CheckboxInput
                      name='enableASGCapacityRebalancePlaceholder'
                      label={display.enableASGCapacityRebalance}
                      disabled
                      fieldHelp={ChangeEnableSpotHelp.EnableCapacityRebalance}
                    />
                  </>
                )}
              </div>
            </Page.FormContent>
          </Page.Box>
          <Page.Buttons>
            <Form.TertiaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress ? display.updateButton : display.savingButton
              }
              disabled={
                submitInProgress || !canChangeEnableSpot || !enableFeature
              }
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

ChangeEnableSpotForm.defaultProps = {
  actionStatus: undefined,
  spotEnabled: false,
  spotSenseEnabled: false,
  spotPercentage: 60,
  baseCapacityOndemand: 1,
  enableASGCapacityRebalance: false,
  autoScalingType: null,
  prestoCluster: null,
  name: undefined,
  status: null,
  submitSuccess: false,
  usePop: false,
  workflowInProgress: null,
};

ChangeEnableSpotForm.propTypes = {
  spotEnabled: bool,
  spotSenseEnabled: bool,
  spotPercentage: number,
  baseCapacityOndemand: number,
  enableASGCapacityRebalance: bool,
  name: string,
  status: string,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  prestoClusterId: string.isRequired,
  submitSuccess: bool,
  usePop: bool,
  workflowInProgress: bool,
  autoScalingType: string,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  staticWorkerNodes: number.isRequired,
  dynamicWorkerNodes: number.isRequired,
};

export default ChangeEnableSpotForm;
