import { createSelector } from 'reselect';
import * as semver from 'semver';
import { isCommunityEditionTenant } from '../AccountSetup/selectors';
import PrestoUsers from '../PrestoUsers';
import { getComputePlane } from '../ComputePlane/selectors';
import { parsePrestoVersion } from '../../utils/versioning';
import * as constants from './constants';

export const getPrestoClusterId = (state, props) => {
  return props.params.prestoClusterId;
};

const prestoClustersSelector = (state) => {
  return state.prestoClusters.prestoClusters;
};

const removeNotFound = (prestoCluster) => {
  if (prestoCluster === 'not found') {
    return null;
  }
  return prestoCluster;
};

export const getPrestoClusters = createSelector(
  prestoClustersSelector,
  (prestoClusters) => {
    if (!prestoClusters) return null;
    let pcs = Array.from(prestoClusters.values(), removeNotFound);
    pcs = pcs.filter((pc) => {
      return pc !== null;
    });
    return pcs;
  },
);

const statusFilter = (status) => {
  return (prestoCluster) => {
    if (!prestoCluster) {
      return false;
    }

    return status.includes(prestoCluster.status);
  };
};

export const getActivePrestoClusters = createSelector(
  getPrestoClusters,
  (prestoClusters) => {
    if (!prestoClusters) return null;
    return prestoClusters.filter(statusFilter([constants.status.active]));
  },
);

export const getInactivePrestoClusters = createSelector(
  getPrestoClusters,
  (prestoClusters) => {
    if (!prestoClusters) return null;
    return prestoClusters.filter(
      statusFilter([constants.status.destroyed, constants.status.inactive]),
    );
  },
);

export const getPendingPrestoClusters = createSelector(
  getPrestoClusters,
  (prestoClusters) => {
    if (!prestoClusters) return null;
    return prestoClusters.filter(
      statusFilter([
        constants.status.initializing,
        constants.status.init_failure,
      ]),
    );
  },
);

export const getPrestoCluster = (state, props) => {
  const prestoClusterId = getPrestoClusterId(state, props);
  if (!state.prestoClusters.prestoClusters) {
    return null;
  }
  const pc = state.prestoClusters.prestoClusters.get(prestoClusterId);
  if (pc === 'not found') {
    return null;
  }
  return pc;
};

export const canCreateCluster = createSelector(
  getPrestoClusters,
  isCommunityEditionTenant,
  (prestoClusters, isCommunityEdition) => {
    return (
      !isCommunityEdition || (!!prestoClusters && prestoClusters.length === 0)
    );
  },
);

export const isCommunityEditionPrestoCluster = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.clusterConfiguration.isCommunityEdition;
  },
);

export const getPrestoClusterCredentials = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.credentials;
  },
);

export const getPrestoClusterStatus = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.status;
  },
);

export const getPrestoClusterName = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.name;
  },
);

export const getPrestoClusterSubdomain = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.subdomainName;
  },
);

export const getIsNotFound = (state, props) => {
  const prestoClusterId = getPrestoClusterId(state, props);
  if (!state.prestoClusters.prestoClusters) {
    return null;
  }
  const pc = state.prestoClusters.prestoClusters.get(prestoClusterId);
  if (pc === 'not found') {
    return true;
  }
  return false;
};

export const getError = (state) => {
  return state.prestoClusters.error;
};

export const getLoadingInProgress = (state) => {
  return state.prestoClusters.loadingInProgress;
};

export const getActionStatus = (state, props) => {
  const prestoClusterId = getPrestoClusterId(state, props);
  const status = state.prestoClusters.actionStatus.get(prestoClusterId);
  return status;
};

export const getUsePop = (state) => {
  return state.currentRoute.hasLocationHistory > 0;
};

export const getDataSourceIds = (state, props) => {
  if (!state.prestoClusters.prestoClustersDataSourceIds) {
    return null;
  }

  const prestoClusterId = getPrestoClusterId(state, props);
  return state.prestoClusters.prestoClustersDataSourceIds.get(prestoClusterId);
};

const getDataSourcesMap = (state) => {
  return state.dataSources.dataSources;
};

export const getDataSources = createSelector(
  getDataSourceIds,
  getDataSourcesMap,
  (dataSourceIds, dataSources) => {
    if (!dataSourceIds) {
      return null;
    }

    if (dataSourceIds.length === 0) {
      return [];
    }

    const results = dataSourceIds.map((id) => {
      return dataSources.get(id);
    });

    return results;
  },
);

export const getAutoScalingType = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster || !prestoCluster.autoScaling) return null;

    return prestoCluster.autoScaling.type;
  },
);

export const getPrestoClusterAutoScalingPolicy = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.autoScaling;
  },
);

export const getStaticWorkerNodes = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster || !prestoCluster.autoScaling) return null;

    return prestoCluster.autoScaling.workerNodes;
  },
);

export const getDynamicWorkerNodes = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster || !prestoCluster.autoScaling) return null;

    return prestoCluster.autoScaling.maxWorkerNodes;
  },
);

export const getPrestoVersion = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.clusterConfiguration.prestoVersion;
  },
);

export const getOAuth2PrestoCluster = createSelector(
  getPrestoClusters,
  (prestoClusters) => {
    if (!prestoClusters) return null;

    return prestoClusters.find((pc) => {
      return (
        pc.clusterConfiguration?.oauth2Enabled === true &&
        pc.status !== constants.status.destroyed
      );
    });
  },
);

export const getPrestoClusterGroupedConfig = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.clusterConfiguration.concurrency;
  },
);

export const getPrestoClusterSpotEnabled = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;
    return !!prestoCluster.clusterConfiguration.enableSpot;
  },
);

export const getPrestoClusterSpotSenseEnabled = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;
    return !!prestoCluster.clusterConfiguration.enableSpotSense;
  },
);

export const getPrestoClusterSpotPercentage = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;
    return prestoCluster.clusterConfiguration.prestoWorkerSpotPercentage;
  },
);

export const getPrestoClusterBaseCapcityOndemand = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;
    return prestoCluster.clusterConfiguration.ondemandBaseCapacity;
  },
);

export const getEnableASGCapacityRebalance = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;
    return prestoCluster.clusterConfiguration.enableASGCapacityRebalance;
  },
);

export const getPrestoClusterCustomPluginBucketLink = createSelector(
  getComputePlane,
  getPrestoCluster,
  (computePlane, prestoCluster) => {
    if (
      !computePlane ||
      !computePlane.awsRegion ||
      !prestoCluster ||
      !prestoCluster.clusterConfiguration?.customPluginBucket ||
      !prestoCluster.clusterConfiguration?.customPluginBucketPrefix
    )
      return null;

    const bucketName = prestoCluster.clusterConfiguration.customPluginBucket;
    const bucketPrefix =
      prestoCluster.clusterConfiguration.customPluginBucketPrefix;
    const region = computePlane.awsRegion;

    const customPluginBucketLink = `https://s3.console.aws.amazon.com/s3/buckets/${bucketName}?region=${region}&bucketType=general&prefix=${bucketPrefix}`;

    return customPluginBucketLink;
  },
);

export const getPrestoClusterPrestoUserIds = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.prestoUserIds;
  },
);

export const getPrestoUsers = createSelector(
  getPrestoCluster,
  PrestoUsers.selectors.getPrestoUsers,
  (prestoCluster, prestoUsers) => {
    if (!prestoCluster || !prestoCluster.prestoUserIds || !prestoUsers)
      return null;

    const users = prestoUsers.filter((pu) => {
      return prestoCluster.prestoUserIds.includes(pu.prestoUserId);
    });
    return users;
  },
);

export const getDownloadLinks = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) {
      return null;
    }
    const { prestoVersion } = prestoCluster.clusterConfiguration;
    if (!prestoVersion) {
      return null;
    }

    if (prestoCluster?.clusterConfiguration?.oauth2Enabled) {
      const hostname = 'ibm.biz';
      const { prestoVersion: presto } = parsePrestoVersion(prestoVersion);

      return {
        prestoCLI: `https://${hostname}/presto-cli-${presto.replace(
          '.',
          '-',
        )}-watsonx-data-executable-jar`,
        prestoJDBC: `https://${hostname}/presto-jdbc-${presto.replace(
          '.',
          '-',
        )}-watsonx-data-jar`,
      };
    }

    const { prestoVersion: presto } = parsePrestoVersion(prestoVersion);
    return {
      prestoCLI: `https://repo1.maven.org/maven2/com/facebook/presto/presto-cli/${presto}/presto-cli-${presto}-executable.jar`,
      prestoJDBC: `https://repo1.maven.org/maven2/com/facebook/presto/presto-jdbc/${presto}/presto-jdbc-${presto}.jar`,
    };
  },
);

export const getShowSendDebugging = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) {
      return false;
    }
    const { prestoClusterVersion } = prestoCluster;
    if (!prestoClusterVersion) {
      return null;
    }
    return semver.gte(prestoClusterVersion, '4.20.0');
  },
);

export const getPrestoClusterWorkflowInProgress = createSelector(
  getPrestoCluster,
  (prestoCluster) => {
    if (!prestoCluster) return null;

    return prestoCluster.workflowInProgress;
  },
);
