import Help from '../../Help';

const EnableSpot = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Enable Spot</Help.HelpHeader>
      <Help.P>
        If enabled, the Presto worker nodes will be provisioned with on-demand
        instances and spot instances.
      </Help.P>

      <Help.P>
        This will decrease compute cost but might increase the risk of query
        failure.
      </Help.P>

      <Help.P>
        If spot instances are being reclaimed by AWS, the coordinator will
        remove the Spot worker node and retry provisioning new instances.
      </Help.P>
    </Help.HelpSection>
  );
};

const EnableCapacityRebalance = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Capacity Rebalance</Help.HelpHeader>
      <Help.P>
        If your cluster is using spot instances and you are experiencing churn
        of the workers, disabling capacity rebalancing may reduce churn.
      </Help.P>
      <Help.P>
        The downside to disabling capacity rebalancing is that when a spot
        instance is terminated due to ec2 demand, a replacement instance request
        will not begin until the original instance is terminated. With capacity
        rebalancing enabled, a replacement instance is requested immediately
        when notification of rebalance recommendation or spot instance
        termination is received.
      </Help.P>
    </Help.HelpSection>
  );
};

const EnableSpotSense = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Enable Spot Sense</Help.HelpHeader>
      <Help.P>
        Turns on or off spot sense for spot instances for the autoscaling group.
      </Help.P>
      <Help.P>
        When enabled, the Auto Scaling Group is monitored, and instances will
        fall back to On-Demand if necessary.
      </Help.P>
    </Help.HelpSection>
  );
};

const SpotPercentageWorker = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Spot Percentage</Help.HelpHeader>
      <Help.P>
        Presto worker nodegroup spot instance percentage value is between 0 to
        100.
      </Help.P>
    </Help.HelpSection>
  );
};

const OndemandBaseCapacity = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>Ondemand Base Capacity</Help.HelpHeader>
      <Help.P>
        Specify how much OnDemand capacity the EKS worker nodegroup ASG should
        have for its base portion before scaling by percentages.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  EnableSpot,
  EnableCapacityRebalance,
  EnableSpotSense,
  SpotPercentageWorker,
  OndemandBaseCapacity,
};

export default module;
