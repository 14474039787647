import styled from 'styled-components/macro';
import { colors, fonts } from '../../../css';

export const FooterDiv = styled.div`
  flex: 0 0 auto;
  width: 100%;
  background-color: ${colors.footer};
  padding: 20px 40px;
`;

const minPageWidth = 500;
const maxPageWidth = 1500;
export const FooterBoxDiv = styled.div`
  width: 100%;
  min-width: ${minPageWidth}px;
  max-width: ${maxPageWidth}px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: ${colors.section_background};
  border: 1px solid ${colors.success};
  border-radius: 4px;
  &.error {
    border-color: ${colors.error};
  }

  &.warning {
    border-color: ${colors.warning};
  }

  h3 {
    margin: 0;
    ${fonts.medium_bold}
  }

  > div {
    flex: 0 0 auto;
    padding: 15px 0 15px 15px;
  }

  > div.icon {
    > svg {
      width: 40px;
      fill: ${colors.error};
    }
  }

  > div.message {
    flex: 1 1 auto;

    color: ${colors.font_light};
    > div {
      ${fonts.medium_small}
      letter-spacing: 0.14px;
    }
  }

  > div.details {
    > h3 {
      color: ${colors.warning};
    }
  }

  > div:last-child {
    padding-left: 30px;
    padding-right: 15px;
  }
`;
