import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { bool, number } from 'prop-types';
import Data from '../../Data';
import Form from '../../Form';
import { useIsCommunityEditionTenant } from '../../utils/communityEditionHooks';
import * as constants from '../constants';
import * as utils from '../utils';
import Help from './SpotSettingsHelp';

const display = {
  formContentHeader: 'Spot Configurations',
  enableSpotLabel: 'Enable Spot Worker Nodes',
  enableSpotSenseLabel: 'Spot Sense',
  spotpercentageLabel: 'Spot Percentage (%)',
  ondemandbasecapacityLabel: 'Ondemand Base Capacity',
  enableasgcapacityrebalanceLabel: 'Enable ASG Capacity Rebalance',
  ondemandbasecapacityError:
    'Ondemand Base Capcity must be b/w 0 to maximum number of worker nodes',
  spotpercentagError: 'Enter valid percentage value from 0 to 100',
};

const types = {
  dynamic: 'dynamic',
  idleCostSavings: constants.autoScaling.idleCostSavings,
};

const SpotSettings = ({
  disabled,
  enableEnableSpot,
  enableSpotSense,
  spotPercentage,
  ondemandBaseCapcity,
  asgCapacityRebalance,
}) => {
  const isCommunityEditionTenant = useIsCommunityEditionTenant();

  const { watch, trigger, getValues } = useFormContext();

  const enableSpotSelect = watch('enableSpot');
  const staticWorkerNodes = watch('autoScaling.workerNodes');
  const dynamicWorkerNodes = watch('autoScaling.maxWorkerNodes');
  const scalingType = watch('autoScaling.type', types.idleCostSavings);

  const spotPercentageValidation = useMemo(() => {
    return () => {
      const values = getValues();
      return utils.mustBePercentageInRange(
        0,
        100,
        display.spotpercentagError,
      )(values.prestoWorkerSpotPercentage);
    };
  }, [getValues]);

  const ondemandBaseCapacityValidation = useMemo(() => {
    return () => {
      let maxOndemandBaseCapcity = dynamicWorkerNodes;
      if (scalingType === types.idleCostSavings)
        maxOndemandBaseCapcity = staticWorkerNodes;
      const values = getValues();
      return utils.mustBePercentageInRange(
        0,
        maxOndemandBaseCapcity,
        display.ondemandbasecapacityError,
      )(values.ondemandBaseCapacity);
    };
  }, [getValues, scalingType, staticWorkerNodes, dynamicWorkerNodes]);

  useEffect(() => {
    trigger('ondemandBaseCapacity');
  }, [scalingType, staticWorkerNodes, dynamicWorkerNodes, trigger]);

  return (
    <>
      <div>
        <h3>{display.formContentHeader}</h3>
      </div>
      <div>
        {enableEnableSpot && (
          <Form.CheckboxInput
            name='enableSpot'
            label={display.enableSpotLabel}
            fieldHelp={Help.EnableSpot(isCommunityEditionTenant)}
            disabled={disabled}
          />
        )}
        {enableSpotSelect && (
          <>
            <Form.CheckboxInput
              name='enableSpotSense'
              label={display.enableSpotSenseLabel}
              fieldHelp={Help.EnableSpotSense(isCommunityEditionTenant)}
              disabled={disabled}
              defaultChecked={enableSpotSense}
            />
            <Form.TextInput
              name='prestoWorkerSpotPercentage'
              type='integer'
              label={display.spotpercentageLabel}
              defaultValue={spotPercentage}
              disabled={disabled}
              validationRules={{
                required: display.spotpercentagError,
                validate: spotPercentageValidation,
                valueAsNumber: true,
              }}
              fieldHelp={Help.SpotPercentage(isCommunityEditionTenant)}
            />
            <Form.TextInput
              name='ondemandBaseCapacity'
              type='integer'
              label={display.ondemandbasecapacityLabel}
              defaultValue={ondemandBaseCapcity}
              disabled={disabled}
              validationRules={{
                required: display.ondemandbasecapacityError,
                validate: ondemandBaseCapacityValidation,
                valueAsNumber: true,
              }}
              fieldHelp={Help.OnDemandBaseCapacity(isCommunityEditionTenant)}
            />
            <Form.CheckboxInput
              name='enableASGCapacityRebalance'
              label={display.enableasgcapacityrebalanceLabel}
              disabled={disabled}
              fieldHelp={Help.EnableASGCapacityRebalance(
                isCommunityEditionTenant,
              )}
              defaultChecked={asgCapacityRebalance}
            />
          </>
        )}
        {!enableSpotSelect && (
          <>
            <Form.CheckboxInput
              name='enableSpotSensePlaceholder'
              label={display.enableSpotSenseLabel}
              disabled
              fieldHelp={Help.EnableSpotSense(isCommunityEditionTenant)}
            />
            <Data.DataField
              label={display.spotpercentageLabel}
              disabled
              fieldHelp={Help.SpotPercentage(isCommunityEditionTenant)}
            />
            <Data.DataField
              label={display.ondemandbasecapacityLabel}
              disabled
              fieldHelp={Help.OnDemandBaseCapacity(isCommunityEditionTenant)}
            />
            <Form.CheckboxInput
              name='enableASGCapacityRebalancePlaceholder'
              label={display.enableasgcapacityrebalanceLabel}
              disabled
              fieldHelp={Help.EnableASGCapacityRebalance(
                isCommunityEditionTenant,
              )}
            />
          </>
        )}
      </div>
    </>
  );
};

SpotSettings.defaultProps = {
  disabled: false,
  enableEnableSpot: false,
  enableSpotSense: false,
  spotPercentage: 60,
  ondemandBaseCapcity: 1,
  asgCapacityRebalance: false,
};

SpotSettings.propTypes = {
  disabled: bool,
  enableEnableSpot: bool,
  enableSpotSense: bool,
  spotPercentage: number,
  ondemandBaseCapcity: number,
  asgCapacityRebalance: bool,
};

export default SpotSettings;
