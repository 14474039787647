import { useCallback, useState, useEffect } from 'react';
import { string, shape, bool, func, object } from 'prop-types';
import Banner from '../../Banner';
import Page from '../../Page';
import Data from '../../Data';
import Form from '../../Form';
import { useEnableFeature } from '../../utils/featureHooks';
import ViewAccessDetails from './ViewAccessDetails';
import StandalonePrometheus from '../../StandalonePrometheus';

const display = {
  header: 'Compute Plane Settings',
  boxTitle: 'AWS Setup',
  accessContentHeader: 'Provisioning Credentials',
  deployContentHeader: 'Compute Plane Deployment',
  formExplaination: 'explaination goes here',
  awsRegionLabel: 'AWS Region',
  awsAvailabilityZoneLabel: 'AWS Availability Zones',
  roleARNLabel: 'Role ARN',
  vpcContentHeader: 'VPC Settings',
  computePlaneVersionLabel: 'Compute Plane Version',
  vpcCIDRLabel: 'IPv4 CIDR Block',
  destroyButtonLabel: 'Destroy',
  confirmTitle: 'Are you sure?',
  confirmDetails:
    'Please confirm you want to remove all AWS resources that have been allocated for this compute plane.',
  cancelButton: 'Cancel',
  confirmButton: 'Confirm',
  destroyTooltip: 'Please delete all Presto clusters first.',
  vpcId: 'VPC ID',
  privateSubnets: 'Private Subnet IDs',
  publicSubnets: 'Public Subnet IDs',
  tenantHeader: 'Tenant Settings',
  crnLabel: 'CRN',
  serviceNameLabel: 'Service Name',
  upgradeButtonLabel: 'Upgrade',
  confirmUpgradeTitle: 'Upgrade Compute Plane',
  confirmUpgradeDetails:
    'Please confirm you wish to upgrade your compute plane to the latest version.',
};

const ComputePlane = ({
  computePlane,
  computePlaneId,
  error,
  submitInProgress,
  canDestroy,
  destroy,
  get,
  getConfiguration,
  controlPlaneAwsAccountId,
  awsExternalId,
  iamRoleUrl,
  awsPermissionBoundaryPolicyContent,
  awsCoreInfrastructurePolicyContent,
  awsOperationsPolicyContent,
  awsTrustRelationshipPolicyContent,
  checkProvisioningPolicies,
  shouldUpgradePolicy,
  missingInfrastructurePolicy,
  awsCloudFormationTemplateS3Url,
  cloudFormationStacksListingUrl,
  computePlaneUpdateDate,
  tenantCRN,
  tenantServiceName,
  cloudFormationTemplate,
  cloudFormationStackName,
  terraformContent,
  terraformFileName,
  roleCreationMethod,
  canUpgrade,
  upgrade,
}) => {
  const enableActions = useEnableFeature({
    requireValidSubscription: false,
    requireActiveComputePlane: true,
    requireComputePlaneGreaterThanMinimumVersion: false,
  });

  useEffect(() => {
    if (computePlaneId) {
      get(computePlaneId);
      getConfiguration(computePlaneId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computePlaneId]);

  const [showDestroyPrompt, setShowDestroyPrompt] = useState(false);
  const onConfirm = useCallback(() => {
    destroy(computePlaneId);
    setShowDestroyPrompt(false);
  }, [destroy, computePlaneId]);
  const onCancel = useCallback(() => {
    setShowDestroyPrompt(false);
  }, []);

  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const onConfirmUpgrade = useCallback(() => {
    upgrade(computePlaneId);
    setShowUpgradePrompt(false);
  }, [upgrade, computePlaneId]);
  const onCancelUpgrade = useCallback(() => {
    setShowUpgradePrompt(false);
  }, []);

  return (
    <Page.Page>
      <Page.PageHeader>
        <h1>{display.header}</h1>
      </Page.PageHeader>
      <Page.Scroll>
        <Page.Box>
          <Page.BoxHeader>
            <h2>{display.boxTitle}</h2>
            <div className='buttons'>
              {canUpgrade && (
                <Form.PrimaryButton
                  disabled={
                    showDestroyPrompt ||
                    showUpgradePrompt ||
                    submitInProgress ||
                    !enableActions ||
                    missingInfrastructurePolicy ||
                    shouldUpgradePolicy
                  }
                  onClick={() => {
                    setShowUpgradePrompt(true);
                  }}
                  label={display.upgradeButtonLabel}
                />
              )}
              <Form.PrimaryButton
                disabled={
                  !canDestroy ||
                  showDestroyPrompt ||
                  showUpgradePrompt ||
                  submitInProgress ||
                  !enableActions
                }
                onClick={() => {
                  setShowDestroyPrompt(true);
                }}
                label={display.destroyButtonLabel}
                tooltip={!canDestroy ? display.destroyTooltip : undefined}
              />
            </div>
          </Page.BoxHeader>
          {error && <Banner title={error.errorMessage} />}
          {showDestroyPrompt && (
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
              isWarning
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancel}
                  label={display.cancelButton}
                />
                <Form.PrimaryButton
                  onClick={onConfirm}
                  label={display.confirmButton}
                />
              </div>
            </Banner>
          )}
          {showUpgradePrompt && (
            <Banner
              title={display.confirmUpgradeTitle}
              details={display.confirmUpgradeDetails}
              isWarning
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={onCancelUpgrade}
                  label={display.cancelButton}
                />
                <Form.PrimaryButton
                  onClick={onConfirmUpgrade}
                  label={display.confirmButton}
                />
              </div>
            </Banner>
          )}
          {computePlane && (
            <Page.FormContent rows={2}>
              <div>
                <h3>{display.accessContentHeader}</h3>
                {/* <div>{display.formExplaination}</div> */}
              </div>
              <div>
                <Data.DataFieldWithLink
                  label={display.roleARNLabel}
                  value={computePlane.awsRoleARN}
                  url={iamRoleUrl}
                />
              </div>
              <div>
                <h3>{display.deployContentHeader}</h3>
                {/* <div>{display.formExplaination}</div> */}
              </div>
              <div>
                <Data.DataField
                  label={display.awsRegionLabel}
                  value={computePlane.awsRegionDisplay}
                />
                {!computePlane.isBYOVPC && (
                  <Data.DataField
                    label={display.awsAvailabilityZoneLabel}
                    value={computePlane?.awsAvailabilityZonesDisplay}
                  />
                )}

                <Data.DataField
                  label={display.computePlaneVersionLabel}
                  value={computePlane.computePlaneVersion}
                />
              </div>
              <div>
                <h3>{display.tenantHeader}</h3>
              </div>
              <div>
                <Data.DataField label={display.crnLabel} value={tenantCRN} />
                {tenantServiceName && (
                  <Data.DataField
                    label={display.serviceNameLabel}
                    value={tenantServiceName}
                  />
                )}
              </div>
              <div>
                <h3>{display.vpcContentHeader}</h3>
                {/* <div>{display.formExplaination}</div> */}
              </div>
              <div>
                {!computePlane.isBYOVPC && (
                  <Data.DataField
                    label={display.vpcCIDRLabel}
                    value={computePlane.vpcCIDR}
                  />
                )}
                {computePlane.isBYOVPC && (
                  <>
                    <Data.DataField
                      label={display.vpcId}
                      value={computePlane.awsCloudConfiguration?.vpcId}
                    />
                    <Data.DataField
                      label={display.privateSubnets}
                      value={computePlane.privateSubnetsDisplay}
                    />
                    <Data.DataField
                      label={display.publicSubnets}
                      value={computePlane.publicSubnetsDisplay}
                    />
                  </>
                )}
              </div>
            </Page.FormContent>
          )}
        </Page.Box>
        <ViewAccessDetails
          controlPlaneAwsAccountId={controlPlaneAwsAccountId}
          awsExternalId={awsExternalId}
          awsPermissionBoundaryPolicyContent={
            awsPermissionBoundaryPolicyContent
          }
          awsCoreInfrastructurePolicyContent={
            awsCoreInfrastructurePolicyContent
          }
          awsOperationsPolicyContent={awsOperationsPolicyContent}
          awsTrustRelationshipPolicyContent={awsTrustRelationshipPolicyContent}
          checkProvisioningPolicies={checkProvisioningPolicies}
          computePlaneId={computePlaneId}
          shouldUpgradePolicy={shouldUpgradePolicy}
          missingInfrastructurePolicy={missingInfrastructurePolicy}
          awsCloudFormationTemplateS3Url={awsCloudFormationTemplateS3Url}
          cloudFormationStacksListingUrl={cloudFormationStacksListingUrl}
          computePlaneUpdateDate={computePlaneUpdateDate}
          cloudFormationTemplate={cloudFormationTemplate}
          cloudFormationStackName={cloudFormationStackName}
          terraformContent={terraformContent}
          terraformFileName={terraformFileName}
          roleCreationMethod={roleCreationMethod}
          error={error}
        />
        <StandalonePrometheus.Container />
      </Page.Scroll>
    </Page.Page>
  );
};

ComputePlane.defaultProps = {
  error: null,
  canDestroy: false,
  computePlane: null,
  computePlaneId: null,
  awsPermissionBoundaryPolicyContent: null,
  awsCoreInfrastructurePolicyContent: null,
  awsOperationsPolicyContent: null,
  awsTrustRelationshipPolicyContent: null,
  shouldUpgradePolicy: false,
  missingInfrastructurePolicy: false,
  tenantCRN: undefined,
  tenantServiceName: undefined,
  canUpgrade: false,
};

ComputePlane.propTypes = {
  error: shape({
    errorMessage: string,
    errorSummary: string,
    errorCode: string,
    cloudformationUrl: string,
  }),
  submitInProgress: bool.isRequired,
  canDestroy: bool,
  computePlane: shape({
    computePlaneId: string.isRequired,
    awsRegionDisplay: string.isRequired,
    awsAvailabilityZonesDisplay: string.isRequired,
    vpcCIDR: string,
    awsRoleARN: string.isRequired,
    computePlaneVersion: string.isRequired,
    isBYOVPC: bool.isRequired,
    privateSubnetsDisplay: string,
    publicSubnetsDisplay: string,
    // eslint-disable-next-line react/forbid-prop-types
    prometheusConfiguration: object,
  }),
  computePlaneId: string,
  destroy: func.isRequired,
  get: func.isRequired,
  getConfiguration: func.isRequired,
  controlPlaneAwsAccountId: string.isRequired,
  awsExternalId: string.isRequired,
  iamRoleUrl: string.isRequired,
  awsPermissionBoundaryPolicyContent: string,
  awsCoreInfrastructurePolicyContent: string,
  awsOperationsPolicyContent: string,
  awsTrustRelationshipPolicyContent: string,
  checkProvisioningPolicies: func.isRequired,
  shouldUpgradePolicy: bool,
  missingInfrastructurePolicy: bool,
  awsCloudFormationTemplateS3Url: string.isRequired,
  cloudFormationStacksListingUrl: string.isRequired,
  computePlaneUpdateDate: string.isRequired,
  tenantCRN: string,
  tenantServiceName: string,
  cloudFormationTemplate: string.isRequired,
  cloudFormationStackName: string.isRequired,
  terraformContent: string.isRequired,
  terraformFileName: string.isRequired,
  roleCreationMethod: string.isRequired,
  canUpgrade: bool,
  upgrade: func.isRequired,
};

export default ComputePlane;
