import { makeReducer } from '../../utils/reducer';
import { prettifyJSON } from '../../utils/json';
import auth from '../../auth';
import * as formWizardActionTypes from '../FormWizard/actionTypes';
import * as actionTypes from './actionTypes';

const initialFormState = {
  awsAccountId: null,
  awsAvailabilityZones: null,
  awsRegion: null,
  awsRoleARN: null,
  method: null,
  vpcCIDR: null,
};

const initialState = {
  computePlanes: null,
  computePlaneLatestVersion: '7.3.0',
  configuration: null,
  awsAZsIDsByRegion: null,
  awsSubnets: null,
  submitInProgress: false,
  hasVerifyRoleSuccess: false,
  verifyRoleInProgress: false,
  verifyRoleError: null,
  error: null,
  formState: initialFormState,
};

const onNavigation = (draft) => {
  draft.submitInProgress = false;
  draft.error = null;
};

const get = (draft, action) => {
  if (!draft.computePlanes) {
    draft.computePlanes = new Map();
  }

  const computePlane = action.payload.apiResult.body;

  const error =
    computePlane.errorMessages === null ? null : computePlane.errorMessages[0];

  if (error) {
    if (error.errorCode === 'provisioning_role_1') {
      error.errorSummary =
        'The IAM permissions policy attached to the role below is out of date. Please edit the policy attached to the role by copying the AWS Policy provided in the Manual Role creation method provided above.';
    }

    draft.error = error;
  }

  draft.computePlanes.set(
    action.payload.params.computePlaneId,
    action.payload.apiResult.body,
  );
};

const getAll = (draft, action) => {
  if (draft.computePlanes) {
    draft.computePlanes.clear();
  } else {
    draft.computePlanes = new Map();
  }
  const { results } = action.payload.apiResult.body;
  for (let i = 0; i < results.length; i += 1) {
    const error =
      results[i].errorMessages === null ? null : results[i].errorMessages[0];

    if (error && error.errorCode === 'provisioning_role_1') {
      error.errorSummary =
        'The IAM permissions policy attached to the role below is out of date. Please edit the policy attached to the role by copying the AWS Policy provided in the Manual Role creation method provided above.';
    }
    draft.computePlanes.set(results[i].computePlaneId, results[i]);
  }
};

const getConfiguration = (draft, action) => {
  draft.configuration = action.payload.apiResult.body;
  draft.configuration.awsPermissionBoundaryPolicyContent = prettifyJSON(
    draft.configuration.awsPermissionBoundaryPolicyContent,
  );
  draft.configuration.awsCoreInfrastructurePolicyContent = prettifyJSON(
    draft.configuration.awsCoreInfrastructurePolicyContent,
  );
  draft.configuration.awsOperationsPolicyContent = prettifyJSON(
    draft.configuration.awsOperationsPolicyContent,
  );
  draft.configuration.awsTrustRelationshipPolicyContent = prettifyJSON(
    draft.configuration.awsTrustRelationshipPolicyContent,
  );
};

const getRegionInfo = (draft, action) => {
  draft.awsAZsIDsByRegion = action.payload.apiResult.body.results;
};

const getVPCSubnets = (draft, action) => {
  draft.awsSubnets = action.payload.apiResult.body.result;
};

const resetAWSMetadata = (draft, action) => {
  draft.awsSubnets = null;
  draft.awsAZsIDsByRegion = null;
};

const submitStart = (draft) => {
  draft.submitInProgress = true;
  draft.error = null;
};

const submitSuccess = (draft) => {
  draft.submitInProgress = false;
  draft.error = null;
};

const submitFailure = (draft, action) => {
  draft.submitInProgress = false;
  draft.error = action.payload.apiResult.body.errorMessage;
};

const signOut = () => {
  return { ...initialState };
};

const getAllFailure = (draft) => {
  draft.computePlanes = null;
};

const getComputePlaneLatestVersion = (draft, action) => {
  draft.computePlaneLatestVersion = action.payload.apiResult.body;
};

const actionReducers = {
  CURRENT_ROUTE_CHANGE: onNavigation,
  [actionTypes.get.success]: get,
  [actionTypes.getAll.success]: getAll,
  [actionTypes.getAll.failure]: getAllFailure,
  [actionTypes.patch.request]: submitStart,
  [actionTypes.patch.success]: submitSuccess,
  [actionTypes.patch.failure]: submitFailure,
  [actionTypes.putDestroy.request]: submitStart,
  [actionTypes.putDestroy.success]: submitSuccess,
  [actionTypes.putDestroy.failure]: submitFailure,
  [auth.actionTypes.signOut]: signOut,
  [actionTypes.postDeploy.request]: submitStart,
  [actionTypes.postDeploy.success]: submitSuccess,
  [actionTypes.postDeploy.failure]: submitFailure,
  [actionTypes.getConfiguration.success]: getConfiguration,
  [actionTypes.getRegionInfo.success]: getRegionInfo,
  [actionTypes.getVPCSubnets.success]: getVPCSubnets,
  [formWizardActionTypes.setActiveStep]: resetAWSMetadata,
  [actionTypes.getComputePlaneLatestVersion.success]:
    getComputePlaneLatestVersion,
};

export default makeReducer(initialState, actionReducers);
